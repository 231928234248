import './FileModal.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import {
  Alert,
  Button,
  CustomInput,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import type {
  AttachmentType,
  Component,
  ComponentData,
} from 'src/types/Component';
import * as Yup from 'yup';

import ButtonPlus from '../../../../components/ButtonPlus/ButtonPlus';
import { fileDescription, fileName } from '../../../../regex';

type Props = {
  componentData: Component;
  editing?: boolean;
  handleSubmit: (
    component: Component,
    values: unknown,
    editingItemIndex?: number,
  ) => void;
  itemId?: number;
  items?: Component[];
  isOpen: boolean;
  noDescription?: boolean;
  noName?: boolean;
  submitting: boolean;
  toggle: () => void;
  type?: AttachmentType;
};
type State = { itemData: ComponentData };
export default class FileModal extends React.Component<Props, State> {
  state: State = {
    itemData: null,
  };

  componentDidUpdate(prevProps) {
    const { itemId, items, isOpen } = this.props;
    if (!prevProps.isOpen && isOpen && items && itemId) {
      const itemData = items.find((item) => item.id === itemId);
      this.setState({ itemData });
    } else if (prevProps.isOpen && !isOpen) {
      this.setState({ itemData: null });
    }
  }

  renderError = (message) => <Alert color="danger">{message}</Alert>;

  render() {
    const {
      componentData,
      handleSubmit,
      editing,
      isOpen,
      noDescription,
      noName,
      submitting,
      toggle,
      type,
    } = this.props;
    const { itemData } = this.state;

    const maxFileSize = 1024 * 1024 * 50; // 50mb

    const initialValues: ComponentData = {
      name: itemData ? itemData.name : '',
      file: null,
    };

    let itemType = 'Image';
    let fileTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];
    let wrongFileTypeMessage = 'The file must be in JPG, PNG, or GIF format';
    let fileIcon = 'file-pdf';

    if (type === 'document') {
      itemType = 'Document';
      fileTypes = [
        'application/pdf',
        'application/msword', // .doc
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
        'application/vnd.ms-excel', // .xls
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
      ];
      wrongFileTypeMessage =
        'The file must be in PDF, DOC, DOCX, XLS or XLSX format';

      if (itemData) {
        const fileType = itemData.originalName.split('.').pop();
        if (['doc', 'docx'].indexOf(fileType) > -1) fileIcon = 'file-word';
        if (['xls', 'xlsx'].indexOf(fileType) > -1) fileIcon = 'file-excel';
      }
    } else if (componentData.type === 'hero') {
      initialValues.omcms = {
        subtextTitle: componentData.data?.omcms?.subtextTitle,
        subtextBody: componentData.data?.omcms?.subtextBody,
        leftGradient: componentData.data?.omcms?.leftGradient,
      };
    } else {
      initialValues.description = itemData ? itemData.description : '';
      initialValues.descriptionHidden = itemData
        ? itemData.descriptionHidden
        : false;
      initialValues.customWidth =
        itemData && itemData.customWidth ? itemData.customWidth : '';
    }

    const fileValidation = Yup.mixed<File>()
      .test(
        'fileType',
        wrongFileTypeMessage,
        (file) => (file && fileTypes.includes(file.type)) || !file,
      )
      .test(
        'fileSize',
        'The file must be less than 50mb',
        (file) => (file && file.size <= maxFileSize) || !file,
      )
      .notRequired();

    return (
      <Modal
        centered
        className="file-modal"
        isOpen={isOpen}
        toggle={toggle}
        data-testid={`fileModal${componentData.id}`}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            name: this.props.noName
              ? null
              : Yup.string()
                  .required('Name is required')
                  .max(255, 'Name must be less than 255 characters')
                  .matches(fileName.pattern, {
                    message: `Name ${fileName.description}`,
                    excludeEmptyString: true,
                  }),
            description:
              itemType === 'Image' && !this.props.noDescription
                ? Yup.string()
                    .required('Description is required')
                    .min(5, 'Description must be at least 5 characters')
                    .max(255, 'Description must be less than 255 characters')
                    .matches(fileDescription.pattern, {
                      message: `Description ${fileDescription.description}`,
                      excludeEmptyString: true,
                    })
                : null,
            customWidth:
              itemType === 'Image'
                ? Yup.number()
                    .min(1, 'Custom width must be at least 1 pixel')
                    .max(2560, 'Custom width cannot exceed 2560 pixels')
                : null,
            file: this.props.itemId
              ? fileValidation
              : fileValidation.required('File is required'),
            subimage: fileValidation,
            omcms: Yup.object().shape({
              subtextTitle: Yup.string()
                .nullable()
                .max(50, 'Subtext title must be less than 50 characters'),
              subtextBody: Yup.string()
                .nullable()
                .max(150, 'Subtext body must be less than 150 characters'),
            }),
          })}
          onSubmit={(values) => {
            if (editing) {
              handleSubmit(
                componentData,
                values,
                values.file && itemData ? 1 : 0,
              );
            } else {
              handleSubmit(componentData, values);
            }
          }}
          render={({ handleBlur, setFieldValue, values }) => {
            return (
              <Form placeholder={undefined}>
                <ModalHeader toggle={toggle}>
                  {editing ? 'Edit ' : 'Add '}
                  {itemType}
                </ModalHeader>
                <ModalBody>
                  {!noName && (
                    <div>
                      <label htmlFor="name">{itemType} Name</label>
                      <Input id="name" name="name" type="text" tag={Field} />
                      <ErrorMessage name="name" render={this.renderError} />
                    </div>
                  )}
                  {itemType === 'Image' && !noDescription && (
                    <div>
                      <label htmlFor="description">
                        Image Description
                        <div className="label-subtext">
                          A brief description of the image which will be used as
                          its alt text
                        </div>
                      </label>
                      <Input
                        id="description"
                        name="description"
                        type="text"
                        tag={Field}
                      />
                      <ErrorMessage
                        name="description"
                        render={this.renderError}
                      />
                      <div className="hide-description">
                        <label htmlFor="descriptionHidden">
                          <span>Hide Image Description</span>
                          <Input
                            id="descriptionHidden"
                            name="descriptionHidden"
                            type="checkbox"
                            checked={values.descriptionHidden}
                            tag={Field}
                          />
                          <div className="label-subtext">
                            Check to hide the image description
                          </div>
                        </label>
                      </div>
                      <div className="custom-width">
                        <label htmlFor="customWidth">
                          Custom Image Width
                          <div className="label-subtext">
                            Optionally specify a width (in pixels) to override
                            the default
                          </div>
                        </label>
                        <Input
                          id="customWidth"
                          name="customWidth"
                          type="number"
                          data-testid="customWidthField"
                          tag={Field}
                        />
                        <ErrorMessage
                          name="customWidth"
                          render={this.renderError}
                        />
                      </div>
                    </div>
                  )}
                  {itemData && (
                    <div className="current-item">
                      <label>Current {itemType}</label>
                      {type === 'document' ? (
                        <button
                          className={`current-doc-link ${
                            fileIcon !== 'file-pdf' && 'disabled'
                          }`}
                          onClick={() => window.open(itemData.url)}
                          type="button"
                          disabled={fileIcon !== 'file-pdf'}
                        >
                          <FontAwesomeIcon
                            className="file-icon"
                            /* @ts-expect-error we add all icons on App.js so this is correct */
                            icon={fileIcon}
                            data-testid="currentDocumentIcon"
                          />
                        </button>
                      ) : (
                        <img
                          src={itemData.url}
                          alt=""
                          data-testid="currentImage"
                        />
                      )}
                    </div>
                  )}
                  <div>
                    <label htmlFor="file">
                      {itemData ? (
                        <span>
                          New {itemType}
                          <span className="label-subtext">
                            {' '}
                            (Leave blank to keep current{' '}
                            {itemType.toLowerCase()})
                          </span>
                        </span>
                      ) : componentData.type === 'hero' ? (
                        'Hero Image File'
                      ) : (
                        'File'
                      )}
                    </label>
                    <CustomInput
                      id="file"
                      name="file"
                      type="file"
                      data-testid="fileField"
                      label={values.file ? values.file.name : 'Choose file'}
                      onChange={(e) => {
                        setFieldValue('file', e.currentTarget.files[0]);
                        handleBlur(e);
                      }}
                    />
                    <ErrorMessage name="file" render={this.renderError} />
                  </div>
                  {componentData.type === 'hero' && (
                    <div className="hero-form-contents">
                      {componentData.data?.omcms?.images?.length > 0 && (
                        <div className="current-item">
                          <label>Current Hero Image</label>
                          <img
                            src={componentData.data.omcms.images[0].url}
                            alt="Hero"
                            data-testid="currentHeroImage"
                          />
                        </div>
                      )}
                      <div>
                        <label htmlFor="subimage">
                          Hero Subimage File (optional)
                        </label>
                        <CustomInput
                          id="subimage"
                          name="subimage"
                          type="file"
                          data-testid="subimageFileField"
                          label={
                            values.subimage
                              ? values.subimage.name
                              : 'Choose file'
                          }
                          onChange={(e) => {
                            setFieldValue('subimage', e.currentTarget.files[0]);
                            handleBlur(e);
                          }}
                        />
                        <ErrorMessage
                          name="subimage"
                          render={this.renderError}
                        />
                        {componentData.data?.omcms?.subimage && (
                          <div className="current-item">
                            <label>Current Hero Subimage</label>
                            <img
                              src={componentData.data.omcms.subimage.url}
                              alt="Hero Subimage"
                              data-testid="currentHeroSubimage"
                            />
                          </div>
                        )}
                      </div>
                      <div>
                        <label htmlFor="hero-subtext-title">
                          Hero Subtext Title (optional)
                        </label>
                        <Input
                          id="hero-subtext-title"
                          name="omcms.subtextTitle"
                          data-testid="subtextTitleField"
                          type="text"
                          tag={Field}
                        />
                        <ErrorMessage
                          name="omcms.subtextTitle"
                          render={this.renderError}
                        />
                      </div>
                      <div>
                        <label htmlFor="hero-subtext-body">
                          Hero Subtext Body (optional)
                        </label>
                        <Input
                          id="hero-subtext-body"
                          name="omcms.subtextBody"
                          data-testid="subtextBodyField"
                          type="text"
                          tag={Field}
                        />
                        <ErrorMessage
                          name="omcms.subtextBody"
                          render={this.renderError}
                        />
                      </div>
                      <div className="left-gradient">
                        <label htmlFor="left-gradient">
                          <span>Apply Hero left gradient (optional)</span>
                          <Input
                            id="left-gradient"
                            name="omcms.leftGradient"
                            data-testid="leftGradientField"
                            type="checkbox"
                            checked={values.omcms?.leftGradient}
                            tag={Field}
                          />
                          <div className="label-subtext">
                            Recommended when using the subtext fields over a
                            light background
                          </div>
                        </label>
                      </div>
                    </div>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={toggle}>
                    Cancel
                  </Button>
                  <ButtonPlus
                    color="primary"
                    loading={submitting}
                    type="submit"
                    style={{ width: '150px' }}
                  >
                    Save
                  </ButtonPlus>
                </ModalFooter>
              </Form>
            );
          }}
        />
      </Modal>
    );
  }
}
