import './CanvasSection.scss';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import Textarea from 'react-textarea-autosize';
import { Alert, Button } from 'reactstrap';
import type { Component, ComponentType } from 'src/types/Component';
import * as Yup from 'yup';

import { debounce } from '../../../../helpers';
import { sectionTitle } from '../../../../regex';

type Props = {
  active?: boolean;
  depth?: number;
  addComponent?: (id: number, types: ComponentType[]) => void;
  componentData?: Component;
  hasChildren?: boolean;
  onChange?: (component: Component, values: Component) => void;
};

export default class CanvasSection extends React.Component<Props> {
  schema = Yup.object().shape({
    title: Yup.string()
      .required('Title is required')
      .max(255, 'Title must be less than 255 characters')
      .matches(sectionTitle.pattern, {
        message: `Section title ${sectionTitle.description}`,
        excludeEmptyString: true,
      }),
  });

  handleChange = debounce((title) => {
    const { componentData, onChange } = this.props;
    const values = {
      title: title,
    };
    onChange(componentData, values);
  }, 100);

  renderError = (message) => <Alert color="danger">{message}</Alert>;

  render() {
    const { active, addComponent, componentData, depth, hasChildren } =
      this.props;

    return (
      <div className="overflow-hidden">
        <Formik
          initialValues={{
            title: componentData.title,
          }}
          onSubmit={() => null}
          validationSchema={this.schema}
          render={() => (
            <Form placeholder={undefined}>
              <Field
                name="title"
                render={({ field, form }) => (
                  <Textarea
                    {...field}
                    className={`section-title-input depth-${depth}`}
                    onChange={(e) => {
                      const value = e.target.value;
                      form.setFieldValue('title', value);
                      this.schema
                        .isValid({
                          title: value,
                        })
                        .then((valid) => {
                          if (valid) this.handleChange(value);
                        });
                    }}
                    readOnly={!active}
                    data-testid="sectionTitle"
                  />
                )}
              />
              <ErrorMessage name="title" render={this.renderError} />
            </Form>
          )}
        />
        {(!hasChildren || active) && (
          <Button
            color="secondary"
            outline
            onClick={() =>
              addComponent(componentData.id, depth >= 3 ? ['section'] : null)
            }
          >
            Add a Component
          </Button>
        )}
      </div>
    );
  }
}
