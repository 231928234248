import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import {
  Alert,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import type { Component, ComponentLocation } from 'src/types/Component';
import * as Yup from 'yup';

import ButtonPlus from '../../../../components/ButtonPlus/ButtonPlus';
import { alphabet, midPoint } from '../../../../helpers';
import { locationName } from '../../../../regex';
import ColorPicker from './ColorPicker';
import GooglePlaceAutocomplete from './GooglePlaceAutocomplete';

type Props = {
  error?: string;
  handleMapUpdate?: (component: Component, values: Component) => void;
  editingLocation?: ComponentLocation;
  componentData: Component;
  editingComponentData: Component;
  isOpen: boolean;
  submitting: boolean;
  toggle: (_: unknown, location?: ComponentLocation) => void;
};

export default class LocationModal extends React.Component<Props> {
  locationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .max(255, 'Name must be less than 255 characters')
      .matches(locationName.pattern, {
        message: `Name ${locationName.description}`,
        excludeEmptyString: true,
      }),
    address: Yup.string().required('Address is required'),
  });

  getVerticalPos = (locations) => {
    const lastLocation = locations[locations.length - 1];
    const lastVpos = lastLocation && lastLocation.pos;
    // returning pos value using last node's pos as start boundry
    return midPoint(lastVpos);
  };

  handleSubmit = (values) => {
    const { componentData, editingLocation, editingComponentData } = this.props;
    const { data } = editingComponentData || componentData;
    let newData = data;
    if (!data.locations) {
      newData = { locations: [] };
    }

    newData.locations.sort((a, b) => {
      if (a.pos < b.pos) {
        return -1;
      }
      if (a.pos > b.pos) {
        return 1;
      }
      return 0;
    });

    geocodeByAddress(values.address)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        values.lat = lat;
        values.lng = lng;
        if (editingLocation) {
          const index = data.locations.findIndex(
            (loc) => loc.name === editingLocation.name,
          );
          values.pos = editingLocation.pos;
          newData.locations.splice(index, 1, values);
        } else {
          values.pos = this.getVerticalPos(newData.locations);
          newData.locations.push(values);
        }
        newData.locations.map((loc, index) => {
          loc.label = alphabet(index);
          return loc;
        });
        this.props.handleMapUpdate(componentData, newData);
      });
  };

  renderError = (message) => <Alert color="danger">{message}</Alert>;

  render() {
    const { isOpen, error, submitting, toggle, editingLocation } = this.props;

    return (
      <Modal
        centered
        isOpen={isOpen}
        toggle={toggle}
        data-testid="locationModal"
      >
        <Formik
          initialValues={
            editingLocation
              ? editingLocation
              : {
                  name: '',
                  address: '',
                  color: '#d33115',
                }
          }
          validationSchema={this.locationSchema}
          onSubmit={(values) => this.handleSubmit(values)}
        >
          <Form placeholder={undefined}>
            <ModalHeader toggle={toggle}>
              {editingLocation ? 'Edit' : 'Add'} Location
            </ModalHeader>
            <ModalBody>
              <label htmlFor="name">Location Name (Pin Pop-up Message)</label>
              <Input id="name" name="name" tag={Field} />
              <ErrorMessage name="name" render={this.renderError} />

              <label htmlFor="address">Address</label>
              <Field
                id="address"
                name="address"
                component={GooglePlaceAutocomplete}
              />
              <ErrorMessage name="address" render={this.renderError} />

              <label htmlFor="color">Map pin color</label>
              <Field name="color" component={ColorPicker} />
              {error && this.renderError(error)}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
              <ButtonPlus color="primary" type="submit" loading={submitting}>
                Save
              </ButtonPlus>
            </ModalFooter>
          </Form>
        </Formik>
      </Modal>
    );
  }
}
