import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import {
  Alert,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import type { Component } from 'src/types/Component';
import * as Yup from 'yup';

import ButtonPlus from '../../../../components/ButtonPlus/ButtonPlus';
import {
  mediaDescription,
  mediaName,
  soundcloudUrl,
  vimeoUrl,
  youtubeUrl,
} from '../../../../regex';

type Props = {
  componentData: Component;
  editing?: boolean;
  editingComponentData: Component;
  handleSubmit: (component: Component, values: Component) => void;
  isOpen: boolean;
  submitting: boolean;
  toggle: () => void;
};

export default class MediaModal extends React.Component<Props> {
  renderError = (message) => <Alert color="danger">{message}</Alert>;

  render() {
    const {
      componentData,
      editingComponentData,
      handleSubmit,
      editing,
      isOpen,
      submitting,
      toggle,
    } = this.props;

    let mediaData = componentData.data;
    if (editingComponentData?.id === componentData.id) {
      mediaData = editingComponentData.data;
    }

    return (
      <Modal
        centered
        className="media-modal"
        isOpen={isOpen}
        toggle={toggle}
        data-testid="mediaModal"
      >
        <Formik
          initialValues={{
            description: mediaData?.description ? mediaData.description : '',
            name: mediaData?.name ? mediaData.name : '',
            source: mediaData?.source ? mediaData.source : '',
            url: mediaData?.url ? mediaData.url : '',
          }}
          validationSchema={Yup.object().shape({
            description: Yup.string()
              .min(5, 'Description must be at least 5 characters')
              .max(255, 'Description must be less than 255 characters')
              .matches(mediaDescription.pattern, {
                message: `Description ${mediaDescription.description}`,
                excludeEmptyString: true,
              }),
            name: Yup.string()
              .required('Name is required')
              .max(255, 'Name must be less than 255 characters')
              .matches(mediaName.pattern, {
                message: `Name ${mediaName.description}`,
                excludeEmptyString: true,
              }),
            source: Yup.string()
              .oneOf(['soundcloud', 'youtube', 'vimeo'])
              .required('Source is required'),
            url: Yup.string()
              .required('URL is required')
              .url('Source must be a valid URL')
              .when('source', {
                is: 'soundcloud',
                then: (schema) =>
                  schema.matches(soundcloudUrl.pattern, {
                    message: soundcloudUrl.description,
                    excludeEmptyString: true,
                  }),
              })
              .when('source', {
                is: 'youtube',
                then: (schema) =>
                  schema.matches(youtubeUrl.pattern, {
                    message: youtubeUrl.description,
                    excludeEmptyString: true,
                  }),
              })
              .when('source', {
                is: 'vimeo',
                then: (schema) =>
                  schema.matches(vimeoUrl.pattern, {
                    message: vimeoUrl.description,
                    excludeEmptyString: true,
                  }),
              }),
          })}
          onSubmit={(values) => {
            const submitValues = {
              ...values,
            };
            submitValues.description = values.description || null;
            handleSubmit(componentData, submitValues);
          }}
          render={({ setFieldValue, values }) => {
            return (
              <Form placeholder={undefined}>
                <ModalHeader toggle={toggle}>
                  {editing ? 'Edit ' : 'Add '}Media
                </ModalHeader>
                <ModalBody>
                  <label htmlFor="name">Media Name</label>
                  <Input id="name" name="name" type="text" tag={Field} />
                  <ErrorMessage name="name" render={this.renderError} />

                  <label htmlFor="source">Media Source</label>
                  <Input
                    type="select"
                    name="source"
                    id="source"
                    onChange={(e) => {
                      setFieldValue('source', e.target.value);
                    }}
                    value={values.source}
                  >
                    <option value="">Select source...</option>
                    <option value="soundcloud">SoundCloud</option>
                    <option value="youtube">YouTube</option>
                    <option value="vimeo">Vimeo</option>
                  </Input>
                  <ErrorMessage name="source" render={this.renderError} />

                  <label htmlFor="url">Media URL</label>
                  <Input id="url" name="url" type="text" tag={Field} />
                  <ErrorMessage name="url" render={this.renderError} />

                  <label htmlFor="description">Description (optional)</label>
                  <Input
                    id="description"
                    name="description"
                    type="text"
                    tag={Field}
                  />
                  <ErrorMessage name="description" render={this.renderError} />
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={toggle}>
                    Cancel
                  </Button>
                  <ButtonPlus
                    color="primary"
                    loading={submitting}
                    type="submit"
                    style={{ width: '150px' }}
                  >
                    Save
                  </ButtonPlus>
                </ModalFooter>
              </Form>
            );
          }}
        />
      </Modal>
    );
  }
}
