import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { Alert, Input } from 'reactstrap';
import * as Yup from 'yup';

import ButtonPlus from '../../../components/ButtonPlus/ButtonPlus';
import { password, personName } from '../../../regex';

type Props = {
  error: string;
  handleSubmit: (values: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    passwordConfirmation: string;
  }) => void;
  submitting: boolean;
};

export default class SignUpForm extends React.Component<Props> {
  signUpSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('First name is required')
      .max(70, 'First name must be less than 70 characters')
      .matches(personName.pattern, {
        message: `First name ${personName.description}`,
        excludeEmptyString: true,
      }),
    lastName: Yup.string()
      .required('Last name is required')
      .max(70, 'Last name must be less than 70 characters')
      .matches(personName.pattern, {
        message: `Last name ${personName.description}`,
        excludeEmptyString: true,
      }),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email address is required'),
    password: Yup.string()
      .required('Password is required')
      .min(7, 'Password must be at least 7 characters')
      .matches(password.pattern, {
        message: password.description,
        excludeEmptyString: true,
      }),
    passwordConfirmation: Yup.string()
      .required('Password confirmation is required')
      .min(7, 'Password must be at least 7 characters')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  renderError = (message: string) => <Alert color="danger">{message}</Alert>;

  render() {
    const { error, handleSubmit, submitting } = this.props;
    return (
      <div>
        <h4 className="margin-xx">Sign Up</h4>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordConfirmation: '',
          }}
          validationSchema={this.signUpSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          <Form placeholder={undefined}>
            <label htmlFor="firstName">First name</label>
            <Input id="firstName" name="firstName" type="text" tag={Field} />
            <ErrorMessage name="firstName" render={this.renderError} />
            <label htmlFor="lastName">Last name</label>
            <Input id="lastName" name="lastName" type="text" tag={Field} />
            <ErrorMessage name="lastName" render={this.renderError} />
            <label htmlFor="email">Email</label>
            <Input id="email" name="email" type="email" tag={Field} />
            <ErrorMessage name="email" render={this.renderError} />
            <label htmlFor="password">Password (at least 7 characters)</label>
            <Input id="password" name="password" type="password" tag={Field} />
            <ErrorMessage name="password" render={this.renderError} />
            <label htmlFor="passwordConfirmation">Confirm password</label>
            <Input
              id="passwordConfirmation"
              name="passwordConfirmation"
              type="password"
              tag={Field}
            />
            <ErrorMessage
              name="passwordConfirmation"
              render={this.renderError}
            />
            <ButtonPlus
              className="margin-top-x margin-xx"
              color="primary"
              type="submit"
              loading={submitting}
              style={{ width: '130px' }}
              data-testid="submitButton"
            >
              Register
            </ButtonPlus>
            {error && this.renderError(error)}
          </Form>
        </Formik>
      </div>
    );
  }
}
