import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { Alert } from 'reactstrap';
import type { Offering } from 'src/types/Offering';
import * as Yup from 'yup';

import { debounce } from '../../../../helpers';
import { offeringName } from '../../../../regex';

type Props = {
  offering: Offering;
  onChange: (values: Offering) => void;
};

export default class InspectorOffering extends React.Component<Props> {
  schema = Yup.object().shape({
    name: Yup.string()
      .required('Offering name is required')
      .min(3, 'Offering name must be at least 3 characters')
      .max(128, 'Offering name must be less than 128 characters')
      .matches(offeringName.pattern, {
        message: `Offering ${offeringName.description}`,
        excludeEmptyString: true,
      }),
  });

  handleChange = debounce((name) => {
    const values = {
      name: name,
    };
    this.props.onChange(values);
  }, 100);

  renderError = (message) => <Alert color="danger">{message}</Alert>;

  render() {
    const { offering } = this.props;

    return (
      <div className="inspector-fields">
        <Formik
          initialValues={{
            name: offering.name,
          }}
          validationSchema={this.schema}
          onSubmit={() => null}
        >
          <Form placeholder={undefined}>
            <label htmlFor="offeringName">Offering Name</label>
            <Field
              name="name"
              render={({ field, form }) => (
                <input
                  {...field}
                  id="offeringName"
                  className="form-control"
                  onChange={(e) => {
                    const value = e.target.value;
                    form.setFieldValue('name', value);
                    this.schema
                      .isValid({
                        name: value,
                      })
                      .then((valid) => {
                        if (valid) this.handleChange(value);
                      });
                  }}
                />
              )}
            />
            <ErrorMessage name="name" render={this.renderError} />
          </Form>
        </Formik>
        <label htmlFor="offering-status">Status</label>
        <Alert
          className="offering-status-alert"
          color={offering.published ? 'success' : 'warning'}
        >
          {offering.published ? 'Completed' : 'Draft'}
        </Alert>
      </div>
    );
  }
}
