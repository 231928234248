import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { Alert, Input } from 'reactstrap';
import * as Yup from 'yup';

import ButtonPlus from '../../../components/ButtonPlus/ButtonPlus';

type Props = {
  error: string;
  handleSubmit: (username: string, password: string) => void;
  submitting: boolean;
};

export default class LoginForm extends React.Component<Props> {
  loginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email address is required'),
    password: Yup.string().min(7).required('Password is required'),
  });

  renderError = (message) => <Alert color="danger">{message}</Alert>;

  render() {
    const { error, handleSubmit, submitting } = this.props;
    return (
      <div>
        <h4 className="margin-xx">Login</h4>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={this.loginSchema}
          onSubmit={(values) => handleSubmit(values.email, values.password)}
        >
          <Form placeholder={undefined}>
            <label htmlFor="email">Email</label>
            <Input id="email" name="email" type="email" tag={Field} />
            <ErrorMessage name="email" render={this.renderError} />
            <label htmlFor="password">Password</label>
            <Input id="password" name="password" type="password" tag={Field} />
            <ErrorMessage name="password" render={this.renderError} />
            <ButtonPlus
              className="margin-top-x margin-xx"
              color="primary"
              type="submit"
              loading={submitting}
              style={{ width: '130px' }}
              data-testid="submitButton"
            >
              Sign In
            </ButtonPlus>
            {error && this.renderError(error)}
          </Form>
        </Formik>
      </div>
    );
  }
}
